<template>
  <section>
    <p class="text-h5 secondary--text">Procesos</p>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          label="Código o nombre"
          v-model="nombreFiltro"
          clearable
          @blur="$v.nombreFiltro.$touch()"
          @input="$v.nombreFiltro.$touch()"
          :error-messages="nombreFiltroError"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          outlined
          label="Modalidad de compra"
          v-model="modalidadFiltro"
          :items="ctlModalidades"
          item-text="nombre"
          item-value="id"
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          outlined
          label="Estado"
          v-model="estadoFiltro"
          :items="ctlEstados"
          item-text="nombre"
          item-value="id"
          clearable
        />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <vc-date-picker
          v-model="fechaDesde"
          mode="date"
          locale="es"
          :max-date="maxFechaDesde"
          :popover="config_calendar"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              :value="fechaDesde"
              class="border roundend focus:outline-none focus:border-blue-300"
              label="Desde"
              outlined
              prepend-inner-icon="mdi-calendar"
              readonly
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <vc-date-picker
          v-model="fechaHasta"
          mode="date"
          locale="es"
          :min-date="minFechaHasta"
          :popover="config_calendar"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              :disabled="!fechaDesde"
              :value="fechaHasta"
              class="border roundend focus:outline-none focus:border-blue-300"
              label="Hasta"
              outlined
              prepend-inner-icon="mdi-calendar"
              readonly
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="d-flex justify-end mt-3">
        <v-btn
          :block="$vuetify.breakpoint.xs ? true : false"
          class="secondary bgWhite--text text-capitalize"
          outlined
          @click="obtainListProcesos(true)"
        >
          Buscar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table-component
          v-models:select="perPageModel"
          v-models:pagina="pageModel"
          :total_registros="total_rows"
          @paginar="paginar"
          :headers="headers"
          :items="items"
        >
          <template #[`item.fecha_inicio_proceso`]="{ item }">
            <span>
              {{ moment(item.fecha_inicio_proceso).format("MMMM / YYYY") }}
            </span>
          </template>
          <template #[`item.fecha_contratacion`]="{ item }">
            <span>
              {{ moment(item.fecha_contratacion).format("DD/MM/YYYY") }}
            </span>
          </template>
          <template #[`item.suma_mercancia`]="{ item }">
            <span>
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.suma_mercancia)
              }}
            </span>
          </template>
          <template v-slot:[`item.seguimiento_proceso`]="{ item }">
            <v-chip
              style="display: block"
              label
              :color="item?.seguimiento_proceso?.color || 'secondary'"
              class="bgWhite--text"
            >
              {{ item?.seguimiento_proceso?.nombre }}
            </v-chip>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top v-if="item?.seguimiento_proceso?.id != 1">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                  @click="mostrar(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span> Mostrar </span>
            </v-tooltip>
            <v-tooltip top v-if="showConfigOptions(item?.seguimiento_proceso)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="secondary"
                  @click="config(item)"
                  v-on="on"
                >
                  mdi-cog-outline
                </v-icon>
              </template>
              <span> Configuración </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import { helpers } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
const txtField = helpers.regex("txtField", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);

export default {
  name: "procesosListView",
  components: {
    DataTableComponent,
  },
  validations: {
    nombreFiltro: {
      txtField,
    },
  },
  data() {
    return {
      // values
      nombreFiltro: null,
      modalidadFiltro: null,
      estadoFiltro: null,
      fechaDesde: null,
      fechaHasta: null,

      items: [],
      ctlModalidades: [],
      ctlEstados: [],
      // config
      paginate: {
        page: 1,
        per_page: 10,
      },
      total_rows: null,
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      config_calendar: {
        visibility: "click",
      },
      headers: [
        {
          text: "Código",
          value: "codigo_proceso",
          class: "secondary bgWhite--text",
          align: "center",
        },
        {
          text: "Proceso",
          value: "nombre_proceso",
          class: "secondary bgWhite--text",
          align: "center",
          sortable: true,
        },
        {
          text: "Modalidad de compra",
          value: "forma_contratacion.nombre",
          class: "secondary bgWhite--text",
          align: "center",
        },
        {
          text: "Mes programado",
          value: "fecha_inicio_proceso",
          class: "secondary bgWhite--text",
          align: "center",
        },
        {
          text: "Fecha solicitado",
          value: "fecha_contratacion",
          class: "secondary bgWhite--text",
          align: "center",
        },
        {
          text: "Monto programado",
          value: "suma_mercancia",
          class: "secondary bgWhite--text",
          align: "center",
        },
        {
          text: "Estado",
          value: "seguimiento_proceso",
          class: "secondary bgWhite--text",
          align: "center",
        },
        {
          text: "Acciones",
          value: "acciones",
          class: "secondary bgWhite--text",
          align: "center",
        },
      ],
    };
  },
  computed: {
    maxFechaDesde() {
      return this.fechaHasta
        ? this.moment(this.fechaHasta, "DD/MM/YYYY")
            .add(1, "day")
            .format("YYYY-MM-DD")
        : null;
    },
    // Models para data table
    pageModel: {
      get() {
        return this.paginate.page;
      },
      set(value) {
        this.paginate.page = value;
      },
    },
    perPageModel: {
      get() {
        return this.paginate.per_page;
      },
      set(value) {
        this.paginate.per_page = value;
      },
    },
    minFechaHasta() {
      return this.fechaDesde
        ? this.moment(this.fechaDesde, "DD/MM/YYYY")
            .add(1, "day")
            .format("YYYY-MM-DD")
        : this.moment().format("YYYY-MM-DD");
    },
    nombreFiltroError() {
      const errors = [];
      if (!this.$v.nombreFiltro.$dirty) return errors;
      !this.$v.nombreFiltro.txtField &&
        errors.push("El campo no puede contener caracteres especiales");
      return errors;
    },
  },
  methods: {
    ...mapMutations("procesoCompra", ["setCorrelativoProceso"]),
    mostrar(item) {
      switch (item?.seguimiento_proceso?.id) {
        case 2:
          this.$router.push({
            name: "resumen-procesos",
            params: { id_proceso: item.id },
          });
          break;
        case 6:
          this.setCorrelativoProceso(item.codigo_proceso);
          this.$router.push({
            name: "seguimiento-contratos",
            params: { id_proceso: item.id },
          });
          break;
      }
    },
    config(item) {
      this.$router.push(`/configuracion-proceso-paac/${item.id}`);
    },
    showConfigOptions(estado) {
      switch (estado.id) {
        case 1:
          return true;
        case 2:
          return true;
        case 3:
          return true;
        default:
          return false;
      }
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.paginate.page = pagina;
      this.paginate.per_page = cantidad_por_pagina;
      this.obtainListProcesos();
    },
    setPaginationData(payload) {
      this.paginate.page = Number(payload.page);
      this.paginate.per_page = Number(payload.per_page);
      this.total_rows = Number(payload.total_rows);
    },
    async obtainListProcesos(resetPagination = false) {
      if (resetPagination) {
        this.paginate.page = 1;
        this.paginate.per_page = 10;
      }
      // check if fechaDesde is a empty string
      if (this.fechaDesde === "") {
        this.fechaDesde = null;
      }
      // check if fechaHasta is a empty string
      if (this.fechaHasta === "") {
        this.fechaHasta = null;
      }

      // validando input nombre de proceso
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.temporalAlert({
          show: true,
          type: "error",
          message:
            "El campo codigo o nombre no puede contener caracteres especiales",
        });
        return;
      }

      if (this.fechaDesde && this.fechaHasta == null) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe seleccionar una fecha hasta",
        });
        return;
      } else if (this.fechaDesde == null && this.fechaHasta) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe seleccionar una fecha desde",
        });
        return;
      }

      // validando fecha hasta mayor a fecha desde
      if (
        this.moment(this.fechaDesde, "DD/MM/YYYY").isAfter(
          this.moment(this.fechaHasta, "DD/MM/YYYY")
        )
      ) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "La fecha desde no puede ser mayor a la fecha hasta",
        });
        return;
      }

      // peticion
      let filters = {
        nombre: this.nombreFiltro ? this.nombreFiltro : undefined,
        id_forma_contratacion: this.modalidadFiltro
          ? this.modalidadFiltro
          : undefined,
        id_seguimiento_proceso: this.estadoFiltro
          ? this.estadoFiltro
          : undefined,
        fecha_desde: this.fechaDesde
          ? this.moment(this.fechaDesde, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined,
        fecha_hasta: this.fechaHasta
          ? this.moment(this.fechaHasta, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined,
      };
      // add values to filters
      filters = {
        ...this.paginate,
        ...filters,
      };
      const response = await this.services.Paac.getListProcesos(filters);
      if (response) {
        this.setPaginationData(response.headers);
        this.items = response.data.procesos;
      }
    },

    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades();
      if (response) {
        this.ctlModalidades = response.data.forma_contratacion;
      }
    },

    async fetchEstados() {
      const response = await this.services.Paac.getEstados();
      if (response) {
        this.ctlEstados = response.data.estados;
      }
    },
  },
  watch: {
    fechaDesde(value) {
      if (value) {
        this.fechaHasta = this.fechaDesde;
      } else {
        this.fechaHasta = null;
      }
    },
  },
  created() {
    this.obtainListProcesos();
    this.fetchModalidades();
    this.fetchEstados();
  },
};
</script>
